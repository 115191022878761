.Container {
  height: 100%;
  display: grid;
  padding: 5px;
}

.CurrentPrice {
  font-size: 1.5em;
  align-self: center;
}

.Variation {
  font-size: 1em;
}

.Positive-Variation {
  color: #4c9141;
  font-size: x-small !important;
}

.Negative-Variation {
  color: #b61616;
  font-size: x-small !important;
}

.Title {
  font-size: small !important;
}
