.Symbol {
  font-size: 4em;
}

.equal {
  color: #cac9ca;
}

.up {
  color: #4c9141;
}

.down {
  color: #b61616;
}
