.Title {
  color: #7d7878;
  font-size: small !important;
  text-align: right;
}

.Subtitle {
  font-size: x-small !important;
  text-align: right;
}
