.SymbolImageContainer {
  min-height: 30px !important;
  min-width: 30px !important;
  max-height: 60px !important;
  max-width: 60px !important;
  padding: 5px;
}

.SymbolImage {
  pointer-events: none;
}

.Subtitle {
  font-size: x-small !important;
}
